import { ManageLayer } from 'modules/management/models/manage-layer';

export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {ManageLayer}
     */
    model(nextOrderNumber = null) {
        let model = new ManageLayer();

        if (nextOrderNumber) {
            model.order = nextOrderNumber;
        }

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.order = {
            type:       'number',
            key:        'order',
            label:      'form.field.order',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        const schema = [
            [this.order],
            [this.name],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.dynamic-fields.manage-layers.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
